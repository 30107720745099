import { css, html, LitElement } from "lit";
import { flexStyles } from "../styles/flex-styles";
import { krumStyles } from "../styles/krum-styles";
import "./krum-web-button";

export class KrumBlogFeature extends LitElement {
    static get properties() {
        return {
            author: {
                type: String,
            },
            role: {
                type: String,
            },
            category: {
                type: String,
            },
            title: {
                type: String,
            },
            preview: {
                type: String,
            },
            date: {
                type: String,
            },
            href: {
                type: String,
            },
            avatar: {
                type: String,
            },
            background: {
                type: String,
            },
        };
    }

    constructor() {
        super();
        this.author = "";
        this.role = "Contributor";
        this.category = "";
        this.title = "";
        this.preview = "";
        this.date = "";
        this.href = "#";
        this.background = "/images/blog-card-background.png";
        this.avatar = "";
    }

    static get styles() {
        return [
            krumStyles,
            flexStyles,
            css`
                :host {
                    width: 360px;
                    max-width: 360px;
                    display: block;
                    aspect-ratio: 9/10;
                    cursor: default;
                }

                /* Respect hidden from parent html */
                :host[hidden] { display: none; } 

                .card-container {
                    overflow: hidden;
                    box-sizing: border-box;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    border-radius: 12px;
                    filter: drop-shadow(2px 2px 12px rgba(0, 0, 0, 0.32));
                    padding: 12px 16px 8px 16px;
                    overflow: hidden;
                    color: var(--krum-muted-white-text-color);
                    text-align: left;
                    width: 100%;
                    height: 100%;
                }

                .hover-items, .hover-items > * {
                    transition: all 0.8s ease-out;
                }
                /* .card-container:not(:hover) .hover-items,
                .card-container:not(:hover) .hover-items > * {
                    height: 0;
                    opacity: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                } */

                .card-header {
                    margin-bottom: 48px;
                }

                .card-header-img {
                    margin-top: 4px;
                    height: 32px;
                    width: 32px;
                    min-width: 32px;
                    min-height: 32px;
                    background-color: var(--krum-light-grey-color);
                    border-radius: 50%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-position-x: right;
                    background-position-y: top;
                }

                .author {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.02em;
                    color: var(--krum-white-text-color);
                }

                .role {
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.02em;
                    color: var(--krum-light-grey-color);
                }

                .dual-line-card-header {
                    gap: 4px;
                    margin-left: 16px;
                }

                .date {
                    margin-left: 16px;
                    margin-top: 4px;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.02em;
                    color: var(--krum-light-grey-color);
                }

                .category {
                    color: var(--krum-genuine-green-color);
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    letter-spacing: 0.02em;
                }

                .title {
                    font-weight: bold;
                    font-size: 28px;
                    line-height: 38px;
                    letter-spacing: 0.02em;
                }

                .preview {
                    margin-top: 2px;
                    font-size: 16px;
                    line-height: 22px;
                    letter-spacing: 0.02em;
                }

                .action-button {
                    margin-top: 20px;
                    margin-bottom: 6px;
                    width: 120px;
                }

                @media all and (max-width: 480px) {
                    :host {
                        width: 100%;
                    }

                    .card-header {
                        margin-bottom: 16px;
                    }

                    .title {
                        font-size: 24px;
                        line-height: 30px;
                    }

                    .preview {
                        font-size: 14px;
                        line-height: 20px;
                    }

                    .title, .preview {
                        -webkit-line-clamp: 2;
                    }

                    .action-button {
                        margin-top: 8px;
                    }
                }

            `,
        ];
    }

    render() {
        return html`
            <!-- TODO: Remove hard coded linear gradient and colors from in-line style -->
            <div class="card-container full-height vertical"
             style="background-image: linear-gradient(0deg, rgba(2, 19, 21, 0.72), rgba(2, 19, 21, 0.72)), url('${this.background}')">

                <!-- Top row:  icon | author + role | date published -->
                <div class="card-header horizontal">
                    <div style="background-image: url(${this.avatar})" class="card-header-img"></div>
                    <div class="dual-line-card-header vertical hide-overflow">
                        <span class="author truncate no-wrap">${this.author}</span>
                        <span class="role truncate no-wrap">${this.role}</span>
                    </div>
                    <div class="flex"></div>
                    <div class="date no-wrap">${this.date}</div>
                </div>
                <div class="flex"></div>


                <!-- Title section: category + title -->
                <div class="category">${this.category}</div>
                <div class="title clamp-3 truncate">${this.title}</div>


                <!-- Hover section: preview + action -->
                <div class="hover-items vertical">
                    <div class="preview hover-items truncate clamp-4">
                        ${this.preview}
                    </div>
                    <krum-web-button type="secondary"
                     class="action-button"
                     href="${this.href}"
                     target="_blank">
                        Read
                    </krum-web-button>
                </div>

            </div>
        `;
    }
}

customElements.define("krum-blog-feature", KrumBlogFeature);
