import { LitElement, html, css } from 'lit';
import { krumStyles } from '../styles/krum-styles.js';
import { flexStyles } from '../styles/flex-styles.js';
import '../components/krum-card-image.js';
import '../components/krum-card-blog-feature.js';
import { DateTime } from 'luxon';


export class KrumSectionBlog extends LitElement {
    static get properties() {
        return {
            type: {
                type: String,
                attribute: true,
            },
            topic: {
                type: String,
                attribute: true,
            },
            API_URL: {
              type: String
            },
            GHOST_API_KEY: {
              type: String,
            }
        };
    }
    constructor() {
        super();
        this.blogContent = [];
        this.topic = "";
    }
    static get styles() {
        return [
            flexStyles,
            krumStyles,
            css`
              :host {
                display: block;
                overflow-x: hidden;
                background-color: var(--krum-background-color);
                font-family: var(--krum-font-family);
                max-width: var(--krum-content-max-width);
                margin-left: auto;
                margin-right: auto;
              }

              :host([type="secondary"]) {
                background-color: var(--krum-secondary-background-color);
              }

              :host([type="action"]) {
                background-color: var(--krum-secondary-text-color);
              }

              :host([type="action"]) .section-title {
                color: var(--krum-white-text-color);
              }

              :host([type="action"]) .section-text {
                  color: var(--krum-muted-white-text-color);
              }

              h1 {
                margin-bottom: 4px;
              }

              h3 {
                color: var(--krum-color-medium-dark-gray);
                margin: 0px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
              }

              hr {
                border: 0px;
                border-top: 1px solid var(--krum-color-medium-gray);
              }



              .section {
                padding: 32px;
                padding-bottom: 120px;
                padding-top: 40px;
              }

              .section > * {
                max-width: var(--krum-content-max-width);
              }

              .section-title {
                font-size: 36px;
                font-weight: bold;
                margin-bottom: 16px;
                color: var(--krum-secondary-text-color);
              }

              .section-text {
                font-size: 24px;
                font-weight: 300;
                line-height: 1.5;
                color: var(--krum-focused-action-color);
                text-align: justify;
              }

              .card-section {
                margin-top: 80px;
              }

              krum-blog-feature {
                margin: 20px;
              }

              /**
              * Mobile considerations for font sizes and section paddings at the
              * 540px breakpoint.  This will also adjust padding for the tile image
              * and the size of the overlay title.
              */
              @media all and (max-width: 540px) {
                .section {
                  padding: 64px 32px;
                }

                .section-title {
                  font-size: 24px;
                }

                .section-text {
                  font-size: 16px;
                }
              }
            `,
        ];
    }
    render() {
        return html`
        <section class="section vertical layout">
            <div>
              <h1>Recent Articles</h1>
            </div>
            <div class="layout horizontal wrap" style="margin-top: 20px">
                ${
                    this.team && this.blogContent.map(post => {
                        return (
                            html`
                                <krum-blog-feature
                                    author="${post.primary_author.name}"
                                    role="${this.team && this.findAuthorInTeam(post.primary_author.name) || 'Contributor'}"
                                    category="${post.primary_tag.name}"
                                    title="${post.og_title || post.title}"
                                    preview="${post.excerpt}"
                                    date="${DateTime.fromISO(post.created_at).toFormat('MMM yyyy')}"
                                    avatar="${post.primary_author.profile_image}"
                                    background="${post.feature_image}"
                                    href="${post.url}"
                                ></krum-blog-feature>
                        `
                        );
                    })
                }
            </div>
        </section>
    `;
    }


    getTeamData() {
        fetch(this.API_URL + '/team')
            .then(resp => resp.json())
            .then(resp => {
                this.team = resp;
                this.requestUpdate('team');
            }).catch(err => {
                console.log(err);
            });
    }

    findAuthorInTeam(name) {
        if(this.team && this.team.length > 0) {
            let member = this.team.find(t => this.compareAuthorNames(name, t.name));
            if(member) {
                return member.title;
            }
        }
        return null;
    }

    compareAuthorNames(a, b) {
        if(!a || !b) {
            return false;
        }
        return a === b || a.replace(/\s/g, '').toLowerCase() === b.replace(/\s/g, '').toLowerCase();
    }

    getBlogData() {
        fetch(`https://krumware.ghost.io/ghost/api/v3/content/posts?key=${this.GHOST_API_KEY}&include=authors,tags&filter=tags%3A%5B${this.topic}%5D&limit=3&order=posts.published_at%20DESC`)
            .then(response => response.json())
            .then(data => {
              this.blogContent = data.posts;
              this.requestUpdate('blogContent');
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    /**
     * Lit element lifecycle callback for when the component has been rendered.
     * This will create a resize event listener on the window which is responsible
     * for resizing the team photos based on available screen width.
     *
     * @returns {void}
     */
    firstUpdated() {
        this.getTeamData();
        this.getBlogData();
    }
}

customElements.define('krum-section-blog', KrumSectionBlog);
